import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


// FIXME: externalize this
const MIXES = [
  "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fsamuelandres%2Freturn-songbyrd-2018-12-08%2F",
  "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fsamuelandres%2Fthe-goldmark-2018-12-07-late-night-happy-hour%2F",
  "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fsamuelandres%2Fpromenade-in-the-pavilion%2F",
  "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fsamuelandres%2F2019-01-08-hotel-monaco-cocktail-hour-mix%2F"
];

const Mixes = () => (
  <Layout>
    <SEO title="Mixes" keywords={['DJ', 'Samuel', 'Andres', 'Pittsburgh', 'House']} />
    <h1>Mixes</h1>
    <ul style={{
      listStyle: "none",
      margin: "0",
      padding: "0"
    }}>
      {MIXES.map((mix) => (
        <li style={{margin: "25px 0", padding: "0" }}>
        <iframe width="100%" height="120" src={mix} frameborder="0" ></iframe>
        </li>
      ))}
    </ul>
  </Layout>
)

export default Mixes